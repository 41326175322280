@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  @apply text-primary-main;
}

::-webkit-scrollbar {
  display: none;
}
